/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

//swiper
// global.scss
@import '~swiper/swiper.min.css';
@import '~@ionic/angular/css/ionic-swiper.css';

.color-bianco {
  color: #fff !important;
}

ion-content {
  background-color: #fff !important;
}

@font-face {
  font-family: 'Gotham Normal';
  src: url('assets/fonts/Montserrat-Regular.eot');
  src: url('assets/fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/Montserrat-Regular.woff2') format('woff2'),
  url('assets/fonts/Montserrat-Regular.woff') format('woff'),
  url('assets/fonts/Montserrat-Regular.ttf') format('truetype'),
  url('assets/fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Medium';
  src: url('assets/fonts/Montserrat-Bold.eot');
  src: url('assets/fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/Montserrat-Bold.woff2') format('woff2'),
  url('assets/fonts/Montserrat-Bold.woff') format('woff'),
  url('assets/fonts/Montserrat-Bold.ttf') format('truetype'),
  url('assets/fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


.font-normal {
  font-family: 'Gotham', sans-serif;
  font-weight: normal;
}

.font-bold {
  font-family: 'Gotham Medium', sans-serif;
  font-weight: 500;
}

p {
  font-family: 'Gotham', sans-serif;
  font-weight: normal;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Gotham Normal', sans-serif;
  font-weight: normal;
}

ion-text {
  color: #386eb6;
}

p {
  margin: 0;
}

.color-blu {
  color: #386eb6;
}

.color-giallo {
  color: #fdb913;
}

ion-content {
  --ion-background-color: transparent !important;
  --ionbackground-image: url("assets/icon/background.png");
  background-size: cover;
}

ion-card {
  box-shadow: 0 0 16px 0 rgb(0 0 0 / 20%);
  background: #fff;
}

a {
  text-decoration: none;
}

input::placeholder {
  color: #b8b8b8;
}

.input_prodotto {
  width: 100%;
  padding: 25px 30px;
  border: none;
  border-radius: 60px;
  box-shadow: 0 0 16px 0 rgb(0 0 0 / 20%);
  color: #386eb6;
  font-weight: bold;
}

.input_prodotto::placeholder {
  color: #386eb6;
  font-weight: bold;
}

.success-snackbar {
  background: #386eb6;
  color: white;
  position: absolute;
  bottom: 0;
}

.success-snackbar button {
  background-color: #386eb6;
  color: white;
  border: none;
}

.input_m_cubi::placeholder {
  text-align: end;
  color: #386eb6;
}

.input_m_cubi_giallo::placeholder {
  text-align: end;
  color: #fdb913;
}

.color_giallo {
  color: #fdb913;
}

.input_pl_blue::placeholder {
  text-align: start;
  color: #386eb6;
}

.active {
  background-color: #386eb6;
  width: 55%;
}

.inactive {
  background-color: #b8b8b8;
  width: 45%;
}

.titolo {
  font-size: 42px;
  color: #386eb6;
  font-family: 'Gotham Medium', sans-serif;
  font-weight: 500;
  margin: 0;
}

.titolo_small {
  font-size: 30px;
  color: #386eb6;
  font-family: 'Gotham Medium', sans-serif;
  font-weight: 500;
  margin: 0;
}

.card {
  border-radius: 40px;
  padding: 20px 25px;
  background-color: #fff;
}

.color_verde {
  color: #28993d;
}

.color_rosso {
  color: #ed1c24;
}

.margin_1 {
  margin: 20px 0 0;
}

.circle_blu {
  height: 55px;
  width: 55px;
  overflow: hidden;
  border-radius: 50%;
  background: #004e7a;
  position: relative;
}

.icon_contacts {
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  font-size: 30px;
}

.copy_payments {
  font-size: 16px;
  margin-left: 7px;
}

.container_input {
  width: 100%;
  box-shadow: 0px 2px 6px 2px rgb(0 0 0 / 8%);
  border-radius: 40px;
  background-color: #fff;
  height: 70px;
}

.input_autolettura {
  height: 100%;
  width: 100%;
  border: none;
  background-color: transparent;
  padding: 0 30px;
}

.error {
  border-radius: 5px;
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  padding: 20px
}

.bottone_accedi {
  background-color: #fccb00;
  border-radius: 2px;
  padding: 12px 0;
  text-align: center;
  font-size: 20px;
}

.input_accesso {
  height: 50px;
  background-color: #fff;
  border-radius: 40px;
  box-shadow: 0px 2px 6px 2px rgb(0 0 0 / 8%);
  border: none;
  width: 100%;
  padding-left: 15px;
}

.bg_blue {
  background-color: #386EB6FF;
}

.bottone_login {
  display: inline-block;
  border-radius: 30px;
  color: #fff;
  padding: 5px 30px;
}
